function formatNumberWithCommas(number) {
	return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

function animateCount(element) {
	const targetValue = element.getAttribute('data-num')
	const parsedNumber = parseFloat(targetValue.replace(/,/g, ''))

	let currentValue = 0
	const step = 25
	const increment = parsedNumber / step

	let interval

	const updateCount = () => {
		currentValue += increment
		if (currentValue >= parsedNumber) {
			currentValue = parsedNumber
			clearInterval(interval)
		}

		const formattedValue = formatNumberWithCommas(Math.round(currentValue))
		element.textContent = `${formattedValue}`
	}

	interval = setInterval(updateCount, step)
}

const animatedCountElements = document.querySelectorAll('.animated-count')

if (animatedCountElements.length) {
	animatedCountElements.forEach(element => {
		animateCount(element)
	})
}
