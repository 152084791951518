/* eslint-disable eqeqeq */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import JustValidate from 'just-validate'
import intlTelInput from 'intl-tel-input'
import Modal from 'bootstrap/js/src/modal.js'
import { langIsEn } from '../authorization/authorization.js'
import formValidation from '../../base/js/formValidation'

import generateDynamicCaptcha from '../../base/js/captcha.js'
;(() => {
	const accSubRegRef = document.getElementById('acc-sub-reg')
	if (!accSubRegRef) return

	const subAndReg = new Modal(accSubRegRef)
	const subAndRegNode = document.getElementById('acc-sub-reg')
	const thanksModal = new Modal(document.getElementById('thanks-modal'))
	const errorModal = new Modal(document.getElementById('error-modal'))

	document.addEventListener('DOMContentLoaded', function () {
		var passwordField = document.getElementById('sub_passwd')
		var togglePasswordButton = document.getElementById('togglePasswordButton')

		if (togglePasswordButton) {
			togglePasswordButton.addEventListener('click', function () {
				if (passwordField.type === 'password') {
					passwordField.type = 'text'
				} else {
					passwordField.type = 'password'
				}
			})
		}
	})

	document.addEventListener('DOMContentLoaded', function () {
		generateDynamicCaptcha()
	})

	const submitBtn = subAndRegNode.querySelector('.account-subscription-page__button-container button')
	const phoneInput = document.querySelector('#phone')
	const phoneInputPrefix = document.querySelector('.tel-prefix')

	const formAction = subAndRegNode.querySelector('form').getAttribute('action')
	const formMethod = subAndRegNode.querySelector('form').getAttribute('method')

	const countryPicker = intlTelInput(phoneInput, {
		utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
	})

	function setPhoneMask(countryCode) {
		phoneInputPrefix.textContent = `+${countryCode}`
	}

	phoneInput.addEventListener('countrychange', () => {
		const selectedCountryData = countryPicker.getSelectedCountryData()
		const selectedCountryCode = selectedCountryData.dialCode
		setPhoneMask(selectedCountryCode)
	})

	const initialCountryData = countryPicker.getSelectedCountryData()
	const initialCountryCode = initialCountryData.dialCode
	setPhoneMask(initialCountryCode)

	const validator = new JustValidate('#sub-register')

	const form = subAndRegNode.querySelector('form')
	if (subAndReg) {
		const validation = new formValidation(form)
		/*validation.addControl('#sub_email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email',
				errorMessage: langIsEn
					? 'Please enter a valid email address'
					: 'Будь ласка, введіть дійсну адресу електронної пошти'
			}
		])
		validation.addControl('#sub_passwd', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password',
				errorMessage: langIsEn
					? 'Password must be at least 8 characters, contain at least one letter and one number'
					: 'Пароль повинен містити щонайменше 8 символів, принаймні одну літеру і одну цифру',
				validate: function (value) {
					var passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/
					var disallowedSymbols = /[\[!@#$%^&*()_+{};:<>,.?~\\/\\]/

					return passwordRegex.test(value) && !disallowedSymbols.test(value)
				}
			}
		])
		validation.addControl('#sub_name', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#sub_surname', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#sub_company', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#sub_oppupation', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#phone', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'customRegexp',
				value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
				errorMessage: langIsEn
					? 'Please enter a valid phone number'
					: 'Будь ласка, введіть правильний номер телефону'
			},
			{
				rule: 'minLength',
				value: 9,
				errorMessage: langIsEn ? 'The minimum length is 9 characters' : 'Мінімальна довжина 9 знаків'
			},
			{
				rule: 'maxLength',
				value: 12,
				errorMessage: langIsEn ? 'The maximum length is 12 characters' : 'Максимальна довжина 12 знаків'
			}
		])*/
		validation.addControl('#privacy', [
			{
				rule: 'required',
				errorMessage: langIsEn
					? 'Please accept our privacy policy'
					: 'Будь ласка, прийміть нашу політику конфіденційності'
			}
		])
		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)

			/*formData.delete('phone')
			formData.append('phone', phoneInputPrefix.textContent + phoneInput.value)*/
			submitFormData(formData)
		})

		const submitFormData = formData => {
			fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json();

						if (json.success > 0) {
							window.location.href = json.return_url;
						} else {
							document.getElementById('form_error_text').innerHTML = json.error
							errorModal.show()
							inquiryModal.hide()
						}
					} else {
						errorModal.show()
						subAndReg.hide()
						document.getElementById('form_error_text').innerHTML = ''
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
					}
				})
				.catch(error => {
					errorModal.show()
					subAndReg.hide()
				})
		}
	}
})()
