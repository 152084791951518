/* eslint-disable vars-on-top */
/* eslint-disable block-scoped-var */
const header = document.querySelector('header')
const headerHeight = header.offsetHeight
const headerBottom = header.querySelector('.header__bottom')
const headerTop = header.querySelector('.header__top')
const headerTopHeight = headerTop.offsetHeight
const dropdownBtns = document.getElementById('dropdownBtns')
const headerMenuItems = document.querySelectorAll('.header-menu__item')
const body = document.querySelector('body')
const mobileMenuBtn = document.querySelector('.header__menu-toggle')
const subMenu = document.querySelectorAll('.has-submenu')
const dropdownTitle = document.querySelector('.header-menu-dropdown__title')
const itemsWithSubmenu = document.querySelectorAll('.header-menu__item.has-submenu')
const submenuLists = header.querySelector('.header-menu-dropdown__lists')
let submenuBackBtns

// hover
if (window.innerWidth >= 960) {
	const itemsWithSubmenu = document.querySelectorAll('.header-menu__item.has-submenu')
	itemsWithSubmenu.forEach(item => {
		item.addEventListener('mouseenter', () => {
			item.classList.add('active')
			body.classList.add('lock')
			if (window.innerWidth >= 960) {
				headerMenuItems.forEach(_otherMenuItem => {
					if (_otherMenuItem !== item) {
						_otherMenuItem.classList.add('disabled')
					}
				})
			}
		})

		item.addEventListener('mouseleave', () => {
			item.classList.remove('active')
			body.classList.remove('lock')
			headerMenuItems.forEach(_otherMenuItem => {
				if (_otherMenuItem !== item) {
					_otherMenuItem.classList.remove('disabled')
				}
			})
		})
	})
}

// mobile menu
var scrollPos = 0

function saveScrollPosition() {
	scrollPos = window.scrollY
}

mobileMenuBtn.addEventListener('click', function () {
	if (header.classList.contains('active')) {
		header.classList.remove('active')
		body.classList.remove('lock')
	} else {
		header.classList.add('active')
		body.classList.add('lock')
	}
})

//resize function

const subMenuMobileClick = () => {
	if (window.innerWidth <= 960) {
		itemsWithSubmenu.forEach(item => {
			item.addEventListener('click', function () {
				if (item.classList.contains('active')) {
					item.classList.remove('active')
				} else {
					item.classList.add('active')
				}
			})
		})
	}
}

window.addEventListener('resize', function () {
	subMenuMobileClick()
})

window.addEventListener('load', function () {
	subMenuMobileClick()
})

if (dropdownTitle) {
	dropdownTitle.addEventListener('click', function () {
		itemsWithSubmenu.classList.remove('active')
	})
}

const closeMobileMenu = () => {
	if (window.innerWidth >= 960) {
		header.classList.remove('active')
		body.classList.remove('lock')

		if (submenuBackBtns) submenuBackBtns.classList.remove('scrolled')

		headerMenuItems.forEach(item => {
			item.classList.remove('active')
			item.classList.remove('disabled')
		})
	}
}

window.addEventListener('resize', closeMobileMenu)
window.addEventListener('load', closeMobileMenu)

// scroll
let lastScrollTop = 0
const ofsetHeader = headerHeight
const offetTopHeader = headerTopHeight

window.addEventListener('scroll', () => {
	let scrollTop = window.scrollY || document.documentElement.scrollTop

	if (scrollTop > ofsetHeader) {
		if (scrollTop < lastScrollTop) {
			headerBottom.classList.add('fixed')
			headerBottom.classList.remove('unvisible')
		} else {
			headerBottom.classList.remove('fixed')
			headerBottom.classList.add('unvisible')
		}
	} else if (scrollTop < offetTopHeader) {
		headerBottom.classList.remove('fixed')
		headerBottom.classList.remove('unvisible')
	}

	lastScrollTop = scrollTop
})

const appHeight = () => {
	const doc = document.documentElement
	doc.style.setProperty('--app-height', `${window.innerHeight}px`)
}
window.addEventListener('resize', appHeight)
window.addEventListener('load', appHeight)
appHeight()
