import getCookie from '../../base/js/getCookie.js'
import setCookie from '../../base/js/setCookie.js'

const lists = document.querySelectorAll('.list')

lists.forEach(list => {
	const items = list.querySelectorAll('.list__items-inner')
	const form = list.querySelector('.list__form')
	const formAction = form?.getAttribute('action') || ''
	const formMethod = form?.getAttribute('method') || ''
	const emptyList = list.querySelector('.list__empty')
	const select = list.querySelector('.select')

	let sortValue = getCookie('sortType')

	/*if (sortValue !== null && select) {
		select.value = sortValue
		select?.dispatchEvent(new Event('change'))
	}*/

	const getRequest = async () => {
		const formData = new FormData(form);
		try {
			const response = await fetch(
				formAction, {
					method: formMethod,
					body: formData 
				}
			).then(async response => {

				if (response.ok) {
					location.reload();
					const responseHtml = await response.text();
					console.log(responseHtml);
					/*items[0].innerHTML = ''
					items[0].innerHTML = responseHtml*/
					if (responseHtml === '') {
						emptyList.style.display = 'none'
					}
				} else {
					console.error('Request failed:', response.status, response.statusText)
				}
			})
			.catch(error => {
				//errorModal.show()
				//submitProjectModal.hide()
				
			})
				
	
		} catch (error) {
			console.error('Fetch error:', error)
		}
	}

	select?.addEventListener('change', () => {
		let selectValue = select.value
		setCookie('sortType', selectValue)
		getRequest()
	})
})