const dropdowns = document.querySelectorAll('.language-dropdown');

dropdowns.forEach(dropdown => {
  dropdown.addEventListener('mouseenter', function () {
    const dropdownButton = dropdown.querySelector('.language-dropdown-btn');
    const dropdownMenu = dropdown.querySelector('.dropdown-menu');

    if (!dropdownButton.classList.contains('show')) {
      dropdownButton.classList.add('show');
      dropdownMenu.style.display = 'block';
    }
  });

  dropdown.addEventListener('mouseleave', function () {
    const dropdownButton = dropdown.querySelector('.language-dropdown-btn');
    const dropdownMenu = dropdown.querySelector('.dropdown-menu');

    if (dropdownButton.classList.contains('show')) {
      dropdownButton.classList.remove('show');
      dropdownMenu.style.display = 'none';
    }
  });
});

function enableClickForMobile(dropdown) {
  const dropdownButton = dropdown.querySelector('.language-dropdown-btn');

  if (window.innerWidth <= 768) {
    dropdownButton.setAttribute('data-bs-toggle', 'dropdown');
    dropdownButton.addEventListener('click', function (e) {
      e.stopPropagation();
    });
  } else {
    dropdownButton.removeAttribute('data-bs-toggle');
    dropdownButton.removeEventListener('click', function (e) {
      e.stopPropagation();
    });
  }
}

window.addEventListener('resize', function () {
  dropdowns.forEach(enableClickForMobile);
});

window.addEventListener('load', function () {
  dropdowns.forEach(enableClickForMobile);
});