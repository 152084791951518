import JustValidate from 'just-validate'
import formValidation from '../../../base/js/formValidation'
import generateDynamicCaptcha from '../../../base/js/captcha'

import { langIsEn, showThanksModal, showErrorModal, authorizationModal, authorizationModalNode } from '../authorization'

const form = authorizationModalNode?.querySelector('#login__form')
if (authorizationModalNode && form) {
	const submitBtn = authorizationModalNode.querySelector('.login__submit')

	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	const validator = new JustValidate(form).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (authorizationModal) {
		const validation = new formValidation(form, {
			lockForm:false
		})

		validation.addControl('#login_email', [
			{
				rule: 'required',
				errorMessage: 'This field is required'
			},
			{
				rule: 'email'
			}
		])

		validation.addControl('#login_password', [
			{
				rule: 'required',
				errorMessage: 'This field is required'
			},
			{
				rule: 'password'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					grecaptcha.ready(function () {
						grecaptcha.reset()
					})

					if (response.ok) {
						const json = await response.json();

						if (json.success === 1) {

							window.location.href = langIsEn ? '/my-account/' : '/ua/my-account/';
							/*form.reset();
							showThanksModal();*/
						} else {
							document.getElementById('login_form_error_text').innerHTML = json.error
							//document.getElementById('form_error_text').innerHTML = json.error;
							//showErrorModal();
						}

					} else {
						document.getElementById('form_error_text').innerHTML = '';
						showErrorModal();
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною');
					}
				})
				.catch(error => {
					showErrorModal()
				})
		}
	}
}
