import JustValidate from 'just-validate'
import formValidation from '../../../base/js/formValidation'

import {
	langIsEn,
	switchedAuthModalView,
	showErrorModal,
	authorizationModal,
	authorizationModalNode
} from '../authorization'

const form = document.getElementById('new-password__form')
if (form) {
	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')
	const submitBtn = form.querySelector('.new-password__submit')
	const returnToProfile = form.querySelector('.new-password__submit-return')

	/*returnToProfile?.addEventListener('click', () => {
		router.push('/personal-account.html')
	})*/

	const validator = new JustValidate(form, {
		lockForm:false
	}).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	if (form) {
		const validation = new formValidation(form, {
			lockForm:false
		})
		validation.addControl('#new-password_password-new', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password'
			}
		])
		validation.addControl('#new-password_password-confirm', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			return fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							location.reload();
						}else{
							document.getElementById('new_pass_error_text').innerHTML = json.error
						}
					} else {
						showErrorModal()
					}
				})
				.catch(error => {
					showErrorModal()
				})
		}
	}
}
