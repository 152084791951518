import Swiper from "swiper";
import { Autoplay } from "swiper/modules";

const sliders = document.querySelectorAll(".startup-list__slider");

sliders.forEach((slider, index) => {
  let idx = index + 1;
  let swiper = new Swiper(slider, {
    spaceBetween: 12,
    modules: [Autoplay],
    autoplay: {
      enabled: false,
    },
    centeredSlides: true,
    speed: 1000,
    slidesPerView: "auto",
    loop: true,
    breakpoints: {
      768: {
        speed: 18000,
        centeredSlides: true,
        autoplay: {
          enabled: true,
          delay: 0,
          disableOnInteraction: false,
          reverseDirection: idx % 2 === 0 ? true : false,
        },
      },
    },
  });
  window.addEventListener("resize", () => {
    swiper.update();

    if (window.innerWidth < 768) {
      swiper.autoplay.stop();
    } else {
      swiper.autoplay.start();
    }
  });
});

