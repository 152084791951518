import JustValidate from 'just-validate'
import intlTelInput from 'intl-tel-input'
import formValidation from '../../base/js/formValidation'
import { errorModal, langIsEn } from '../authorization/authorization.js'

import generateDynamicCaptcha from '../../base/js/captcha.js'

const basicButton = document.querySelector('#basic-edit')
const basicElements = document.querySelectorAll('.account-main__basic-edition')
const basicFormInputs = document.querySelector('.account-main__basic-inputs')
let basicShow = true

const personalButton = document.querySelector('#personal-edit')
const personalElements = document.querySelectorAll('.account-main__personal-edition')
const personalFormInputs = document.querySelector('.account-main__personal-inputs')
let personalShow = true

const basicNode = document.getElementById('basic-info')
const basicSubmitBtn = basicNode?.querySelector('.basic-info__submit')
const basicPhoneInput = basicNode?.querySelector('#basic-info__phone')
const basicPhoneInputPrefix = basicNode?.querySelector('.tel-prefix')

const countryPicker =
	basicNode &&
	intlTelInput(basicPhoneInput, {
		utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
	})

if (basicNode) {
	function setPhoneMask(countryCode) {
		basicPhoneInputPrefix.textContent = `+${countryCode}`
	}

	basicNode &&
		basicPhoneInput.addEventListener('countrychange', () => {
			const selectedCountryData = countryPicker.getSelectedCountryData()
			const selectedCountryCode = selectedCountryData.dialCode
			setPhoneMask(selectedCountryCode)
		})

	const basicFormAction = basicNode && basicNode.getAttribute('action')
	const basicFormMethod = basicNode && basicNode.getAttribute('method')

	const initialCountryData = basicNode && countryPicker.getSelectedCountryData()
	const initialCountryCode = basicNode && initialCountryData.dialCode
	basicNode && setPhoneMask(initialCountryCode)

	const form = basicNode

	const validator = new JustValidate('#basic-info').onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (form) {
		const validation = new formValidation(form, {
			lockForm:false
		})

		validation.addControl('#basic-info__name', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#basic-info__surname', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#basic-info__company', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#basic-info__occupation', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#basic-info__phone', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'customRegexp',
				value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
				errorMessage: langIsEn
					? 'Please enter a valid phone number'
					: 'Будь ласка, введіть правильний номер телефону'
			},
			{
				rule: 'minLength',
				value: 9,
				errorMessage: langIsEn ? 'The minimum length is 9 characters' : 'Мінімальна довжина 9 знаків'
			},
			{
				rule: 'maxLength',
				value: 12,
				errorMessage: langIsEn ? 'The maximum length is 12 characters' : 'Максимальна довжина 12 знаків'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, basicSubmitBtn)
			formData.delete('phone_number')
			formData.append('phone_number', basicPhoneInputPrefix.textContent + basicPhoneInput.value)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			return fetch(basicFormAction, {
				method: basicFormMethod,
				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json()

						if (json.success == 1) {
							const first_name = document.getElementById('basic-info__name').value
							const last_name = document.getElementById('basic-info__surname').value
							const company = document.getElementById('basic-info__company').value
							const occupation = document.getElementById('basic-info__occupation').value
							const phone = document.getElementById('basic-info__phone').value

							// close form and update values
							var name_val = ''
							var d = ''
							if (first_name != '') {
								name_val += first_name
								d = ' '
							}
							if (last_name != '') name_val += d + last_name

							document.getElementById('account_name_val').innerText = name_val

							var occupation_val = ''
							d = ''
							if (company != '') {
								occupation_val += company
								d = ', '
							}
							if (occupation != '') occupation_val += d + occupation

							document.getElementById('account_occupation_val').innerText = occupation_val

							const clear_phone = phone.replace(/(\s|\(|\)|-| )/, '')

							document.getElementById('account_phone_val').innerText =
								basicPhoneInputPrefix.textContent + clear_phone

							basicElements.forEach(element => {
								element.style.display = 'flex'
							})
							basicButton.innerText = 'Edit'
							basicFormInputs.style.display = 'none'

							basicShow = !basicShow

							//form.reset()
							//thanksModal.show()
						} else {
							document.getElementById('form_error_text').innerHTML = json.error
							errorModal.show()
						}
					} else {
						errorModal.show()
						document.getElementById('form_error_text').innerHTML = ''
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
					}
				})
				.catch(error => {
					console.log(error)
					errorModal.show()
				})
		}
	}

	const personalNode = document.getElementById('personal-info')
	const personalSubmitBtn = personalNode?.querySelector('.personal-info__submit')

	const personalFormAction = personalNode && personalNode.getAttribute('action')
	const personalFormMethod = personalNode && personalNode.getAttribute('method')

	const validator2 = new JustValidate(form).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})
	const form2 = personalNode

	if (form2) {
		const validation2 = new formValidation(form2, {
			lockForm:false
		})
		validation2.addControl('#personal-info__email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email'
			}
		])
		validation2.addControl('#personal-info__password', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password'
				// errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])

		validation2.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, personalSubmitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			return fetch(personalFormAction, {
				method: personalFormMethod,

				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json()

						if (json.success == 1) {
							const email = document.getElementById('personal-info__email').value
							const login = document.getElementById('personal-info__login').value

							// close form and update values

							document.getElementById('account_email_val').innerText = email
							document.getElementById('account_login_val').innerText = login

							// update block
							personalElements.forEach(element => {
								element.style.display = 'flex'
							})
							personalButton.innerText = 'Edit'
							personalFormInputs.style.display = 'none'

							personalShow = !personalShow

							document.getElementById('personal-info__password').value = ''
							document.getElementById('personal-info__password_repeat').value = ''

							//form.reset()
							//thanksModal.show()
						} else {
							document.getElementById('form_error_text').innerHTML = json.error
							errorModal.show()
						}
					} else {
						errorModal.show()
						document.getElementById('form_error_text').innerHTML = ''
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
					}
				})
				.catch(error => {
					errorModal.show()
				})
		}
	}

	basicButton &&
		basicButton.addEventListener('click', function (e) {
			e.preventDefault()
			generateDynamicCaptcha()
			if (basicShow) {
				basicElements.forEach(element => {
					element.style.display = 'none'
				})
				this.innerText = 'Cancel'
				basicFormInputs.style.display = 'block'
			} else {
				basicElements.forEach(element => {
					element.style.display = 'flex'
				})
				this.innerText = 'Edit'
				basicFormInputs.style.display = 'none'
			}
			basicShow = !basicShow
		})

	personalButton &&
		personalButton.addEventListener('click', function (e) {
			e.preventDefault()
			generateDynamicCaptcha()

			if (personalShow) {
				personalElements.forEach(element => {
					element.style.display = 'none'
				})
				this.innerText = 'Cancel'
				personalFormInputs.style.display = 'block'
			} else {
				personalElements.forEach(element => {
					element.style.display = 'flex'
				})
				this.innerText = 'Edit'
				personalFormInputs.style.display = 'none'
			}
			personalShow = !personalShow
		})
}
