const fileInputs = document.querySelectorAll('input[type="file"]')

fileInputs.forEach(input => {
	input.addEventListener('change', () => {
		const fileName = input.files[0].name
		const label = input.closest('.form-control').querySelector('.form-control__file-name')
		const removeBtn = input.closest('.form-control').querySelector('.form-control__file-remove')
		label.innerHTML = fileName
		console.log(input.files[0])
		if (fileName) {
			removeBtn.classList.add('active')
		}

		removeBtn.addEventListener('click', () => {
			input.value = ''
			label.innerHTML = ''
			removeBtn.classList.remove('active')
		})
	})
})
