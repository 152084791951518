import JustValidate from 'just-validate'

const handleOnValidate = ({ isSubmitted, fields, groups }) => {
  if (!isSubmitted) return;

  Object.keys(fields).forEach(key => {
    const { config, isValid } = fields[key];
    if (isValid) {
      config.errorsContainer.classList.remove('hasError');
    } else {
      config.errorsContainer.classList.add('hasError');
    }
  });
};

export default class FormValidation {
  _instance;

  constructor(selector) {
    this._init(selector);
  }

  _init(selector) {
    this._instance = new JustValidate(selector).onValidate(handleOnValidate);
  }

  addControl(selector, rules) {
    const elRef = this._instance.form.querySelector(selector);

    if (!selector) {
      throw new Error('No selector found')
    }

    if (!rules) {
      throw new Error('No rules found')
    }

    if (!this._instance) {
      throw new Error('No instance found')
    }

    if (!elRef) {
      throw new Error(`No field with selector ${selector} found`);
    }

    const config = {
      errorsContainer: elRef.closest('.form-control'),
      //lockForm: true,
      //validateBeforeSubmitting: true,
      errorLabelCssClass: ['form-control__error'],
    }

    this._instance.addField(selector, rules, config)
  }

  onSuccess(cb) {
    this._instance.onSuccess(cb)
  }
}
