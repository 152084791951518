/* eslint-disable no-use-before-define */
import * as d3 from 'd3'

import { MAP_WIDTH, MAP_HEIGHT, STATE_STROKE_WIDTH, STATE_OPACITY_DEFAULT, STATE_COLORS } from './investment-map.consts'

export function prepareKey(key) {
	let myString = key
	let trimmedString = myString.trim()

	return trimmedString.replace(/\s+/g, '-')
}

export function toBeatifulPrice(price) {
	return `$${toNumberWithCommas(price)}`
}

export function toBeatifulProjects(projectsCount) {
	return projectsCount;
	/*const count = parseInt(projectsCount, 10) || 0

	return `${count} projects`*/
}

export function selectSvg() {
	return d3
		.select('#investment-map-target')
		.attr('viewBox', [0, 0, MAP_WIDTH, MAP_HEIGHT])
		.attr('width', MAP_WIDTH)
		.attr('height', MAP_HEIGHT)
}

export function createStateGroups(dataFeatures, commonGroup) {
	const groups = commonGroup.selectAll('g').data(dataFeatures).enter().append('g').attr('class', 'state')

	const filteredGroups = groups
		.filter(d => !prepareKey(d.properties.NAME_1).startsWith('Sevastopol'))
		.attr('cursor', 'pointer')

	return {
		groups,
		filteredGroups
	}
}

export function createStatePaths(dataFeatures, commonGroup, path) {
	return commonGroup
		.selectAll('g')
		.data(dataFeatures)
		.join('g')
		.append('path')
		.style('fill-opacity', STATE_OPACITY_DEFAULT)
		.attr('fill', d => {
			// If the state is not in the list of states, return the random color
			const KEY = prepareKey(d.properties.NAME_1)
			if (!STATE_COLORS[KEY]) {
				console.error('Current region not found: ', KEY)

				const stateKeys = Object.keys(STATE_COLORS)
				const random = Math.floor(Math.random() * stateKeys.length)

				return STATE_COLORS[stateKeys[random]]
			}

			return STATE_COLORS[KEY]
		})
		.attr('stroke-width', STATE_STROKE_WIDTH)
		.attr('stroke', '#ffffff')
		.attr('d', path)
}

export function createStateTexts(dataFeatures, commonGroup, path, stateInfo) {
	const texts = commonGroup
		.selectAll('g')
		.data(dataFeatures)
		.join('g.stage')
		.append('text')
		.attr('font-family', 'Geologica, sans-serif')
		.attr('font-size', '14')
		.text(d => {
			const KEY = prepareKey(d.properties.NAME_1)
			if (KEY.startsWith('Sevastopol')) {
				return ''
			}

			return stateInfo[KEY].title || KEY;
		})
		.attr('x', d => prepareTitlePostitionX(path.centroid(d)[0], prepareKey(d.properties.NAME_1)))
		.attr('y', d => prepareTitlePostitionY(path.centroid(d)[1], prepareKey(d.properties.NAME_1)))
		.attr('dominant-baseline', 'middle')
		.attr('text-anchor', 'middle')
		.attr('filter', 'url(#textOutlineEffect)')
		.attr('fill', '#ffffff')

	texts.each(function () {
		const group = d3.select(this.parentNode)
		const { height, width, x, y } = this.getBBox()

		group
			.insert('svg:rect', ':nth-child(2)')
			.attr('class', 'label')
			.attr('rx', height / 2)
			.attr('ry', height / 2)
			.attr('width', width)
			.attr('height', height)
			.attr('x', x)
			.attr('y', y)
			.attr('stroke-alignment', 'outer')
			.attr('stroke-width', '20')
			.attr('fill', 'none')
			.attr('stroke', 'none')
	})

	return texts
}

export function createTooltip(container) {
	const tooltip = container.append('div').attr('class', 'map-tooltip').style('display', 'none')

	const tooltipCoatOfArms = tooltip.append('div').attr('class', 'map-tooltip__coat coats-of-arms-sprite')

	const tooltipCaption = tooltip.append('div').attr('class', 'map-tooltip__caption')

	const tooltipPrice = tooltipCaption.append('div').attr('class', 'map-tooltip__price')

	const tooltipDescription = tooltipCaption.append('div').attr('class', 'map-tooltip__description')

	const tooltipRef = tooltip.node()

	return {
		tooltip,
		tooltipCoatOfArms,
		tooltipCaption,
		tooltipPrice,
		tooltipDescription,
		tooltipRef
	}
}

function toNumberWithCommas(x) {
  const numb = typeof x === 'string' ? parseInt(x, 10) : x;
	// return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
	return numb.toLocaleString("en-US");
}

function prepareTitlePostitionX(x, name) {
	switch (name) {
		case 'Crimea':
			return x * 0.99
		case 'Odesa':
			return x * 1.06
		case 'Rivne':
			return x * 1.06
		case 'Mykolaiv':
			return x * 1.03
		case 'Khmelnytskyi':
			return x * 1.01
		case 'Cherkasy':
			return x * 0.99
		default:
			return x
	}
}

function prepareTitlePostitionY(y, name) {
	switch (name) {
		case 'Cherkasy':
			return y * 1.04
		case 'Sumy':
			return y * 1.1
		case 'Mykolaiv':
			return y * 1.02
		case 'Odesa':
			return y * 0.95
		case 'Kyiv':
			return y + y * 0.2
		default:
			return y
	}
}
