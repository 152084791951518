import JustValidate from 'just-validate'
import formValidation from '../../../base/js/formValidation'
import { langIsEn, showThanksModal, showErrorModal, authorizationModal, authorizationModalNode } from '../authorization'
const form = authorizationModalNode?.querySelector('#verify-email__form')

if (authorizationModalNode && form) {
	const submitBtn = authorizationModalNode.querySelector('.verify-email__submit')

	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	const validator = new JustValidate(form, {
		lockForm:false
	}).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (authorizationModal) {
		const validation = new formValidation(form, {
			lockForm:false
		})

		validation.addControl('#verify-email__code', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'number',
				errorMessage: langIsEn ? 'Please enter numbers only' : 'Будь ласка, вкажіть тільки цифри'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			return fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							//form.reset()
							//showThanksModal()
							window.location.href = langIsEn ? '/my-account/' : '/ua/my-account/';
						} else {
							document.getElementById('reg_verify_form_error_text').innerHTML = json.error
							//showErrorModal()
						}
					} else {
						const text = await response.text();
						document.getElementById('reg_verify_form_error_text').innerHTML = text
						//showErrorModal()
					}
				})
				.catch(error => {
					//showErrorModal()
				})
		}
	}
}
