import "./email-form/email-form";
import "./header/header";
import "./inquiry/inquiry";
import "./investment-map/investment-map";
import "./list/list";
import "./startup-modal/startup-modal";
import "./startups/startups";
import "./submit-project/submit-project";

import "./authorization/authorization";
import "./authorization/login/login";
import "./authorization/new-password/new-password";
import "./authorization/password/password";
import "./authorization/sign-up/sign-up";
import "./authorization/verify-email/verify-email";
import "./authorization/welcome/welcome";
import "./startup-list/startup-list";

import "./account-main/account-main";
import "./language-dropdown/language-dropdown";
import "./modal/modal";
import "./profile-dropdown/profile-dropdown";

import "./account-sub-register-page/account-sub-register-page";
