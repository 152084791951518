import Modal from 'bootstrap/js/src/modal.js'

const startupModalNode = document.getElementById('startup-modal');

if(startupModalNode){
	const startupModal = new Modal(startupModalNode)

	const modalCloseBtns = document.querySelectorAll('.modal-close')

	// modalTriggers.forEach(trigger => {
	// 	trigger.addEventListener('click', () => {
	// 		startupModal.show()
	// 	})
	// })

	modalCloseBtns.forEach(btn => {
		btn?.addEventListener('click', e => {
			e.preventDefault()
			startupModal.hide()
		})
	})
}
