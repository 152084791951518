import TomSelect from 'tom-select/dist/js/tom-select.complete.min.js'

const select = document.querySelectorAll('.select')

if (select.length) {
	select.forEach(item => {
		const customSelect = new TomSelect(item, {
			create: true,
			plugins: ['change_listener']
		})
	})
}
