import JustValidate from 'just-validate'
import formValidation from '../../../base/js/formValidation'
import {
	langIsEn,
	switchedAuthModalView,
	showErrorModal,
	authorizationModal,
	authorizationModalNode
} from '../authorization'

const form = authorizationModalNode?.querySelector('#password__form')

if (authorizationModalNode && form) {
	const forgotPasswordBtn = document.querySelector('.login__pass-recovery')

	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')
	const submitBtn = form.querySelector('.password__submit')

	forgotPasswordBtn.addEventListener('click', () => {
		switchedAuthModalView('showForgotPassword')
	})

	const validator = new JustValidate(form, {
		lockForm: false
	}).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (authorizationModal) {
		const validation = new formValidation(form)

		validation.addControl('#password_email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email'
			}
		])

		validation.onSuccess(event => {
			if (
				form.querySelector('.g-recaptcha-response') &&
				form.querySelector('.g-recaptcha-response').value == ''
			) {
				grecaptcha.ready(function () {
					grecaptcha.execute(form.querySelector('.sw_recaptcha').getAttribute('data-render'))
				})
				return false
			}

			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					grecaptcha.ready(function () {
						grecaptcha.reset()
					})
					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							switchedAuthModalView('showVerifyPassword')
							document.getElementById('forget_error_text').innerHTML = ''
						} else {
							document.getElementById('forget_error_text').innerHTML = json.error
						}
					} else {
						document.getElementById('form_error_text').innerHTML = json.error
						showErrorModal()
					}
				})
				.catch(error => {
					showErrorModal()
				})
		}
	}
}
