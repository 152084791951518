/* eslint-disable consistent-return */
/* eslint-disable no-shadow */

import Modal from 'bootstrap/js/src/modal.js'

if (document.querySelector('#startups') || document.querySelector('#startup-list')) {
	const filters = document.querySelector('.startups__filters')

	const form = document.querySelector('.startups__main')

	const startupsItems = document.querySelectorAll('.startups-item')
	const formAction = form?.getAttribute('action') || ''
	const formMethod = form?.getAttribute('method') || ''
	const filterInputs = filters?.querySelectorAll('input[type="radio"]')
	const items = document.querySelector('.startups__table-inner')

	const startupModal = new Modal(document.getElementById('startup-modal'))
	const inquiryModal = new Modal(document.getElementById('inquiry-modal'))

	if (filters) {
		const getRequestByFilter = async () => {
			const checkedInput = Array.from(filterInputs).find(input => input.checked)

			try {
				if (checkedInput) {
					const formData = new FormData()
					formData.append(checkedInput.getAttribute('name'), checkedInput.getAttribute('id'))

					const response = await fetch('/wp-content/themes/investmap/ajax/startup_filter.php', {
						method: 'POST',
						body: formData
					})

					if (response.ok) {
						const responseHtml = await response.text()
						items.innerHTML = responseHtml
						startupClicker()

						if (responseHtml === '') {
							console.log('empty')
						}
					} else {
						console.error('Request failed:', response.status, response.statusText)
					}
				}
			} catch (error) {
				console.error('Fetch error:', error)
			}
		}

		filterInputs.forEach(input => {
			input.addEventListener('change', () => {
				getRequestByFilter()
			})
		})
	}

	const startupClicker = function () {
		if (document.querySelectorAll('.startups-item, .js-startups-item').length == 0) return

		document.querySelectorAll('.startups-item, .js-startups-item').forEach(item => {
			item.addEventListener('click', event => {
				event.stopPropagation()
				event.preventDefault()

				const id = item?.getAttribute('data-id')
				const formDataStartup = new FormData()
				formDataStartup.append('id', id)

				getRequestOnClick(formDataStartup)
				return false
			})
		})
	}

	startupClicker()

	document.getElementById('startup-modal').querySelector('.modal-close').addEventListener('click', event => {
		startupModal.hide();
	})

	const getRequestOnClick = async data => {
		const modalBg = document.querySelector('.startup-modal__head')
		const modalAvatar = document.querySelector('.startup-modal__img')
		const modalTitle = document.querySelector('.startup-modal__name')
		const modalLink = document.querySelector('.startup-modal__link')
		const modalCategory = document.querySelector('.startup-modal__category')
		const modalDescription = document.querySelector('.startup-modal__desc')
		const modalRound = document.querySelector('.startup-modal__round-val')
		const modalText = document.querySelector('.startup-modal__text')
		try {
			const responseStartupDetail = await fetch('/wp-content/themes/investmap/ajax/startup_detail.php', {
				method: 'POST',
				body: data
			})

			if (responseStartupDetail.ok) {
				const responseHtml = await responseStartupDetail.text()
				document.getElementById('startup-modal').querySelector('.modal-inner').innerHTML = responseHtml
				startupModal.show()

				document.getElementById('startup-modal-inquiry-trigger').addEventListener('click', event => {
					event.stopPropagation()
					event.preventDefault()

					const startupName = document
						.getElementById('startup-modal')
						.querySelector('.startup-modal__name').innerHTML
					document.getElementById('inquiry-startup-name').setAttribute('value', startupName)

					startupModal.hide()
					setTimeout(function () {
						inquiryModal.show()
					}, 600)
				})

				/*const responseHtml = await response.json()

					//need to edit this after get real data

					const { avatar, bg, name, link, category, description, round, text } = responseHtml

					modalBg.querySelector('img').setAttribute('src', bg)
					modalAvatar.querySelector('img').setAttribute('src', avatar)
					modalTitle.innerHTML = name
					modalLink.setAttribute('href', link)
					modalLink.innerHTML = link
					modalCategory.innerHTML = category
					modalDescription.innerHTML = description
					modalRound.innerHTML = round
					modalText.innerHTML = text

					if (responseHtml === '') {
						console.log('empty')
					}*/
				//console.error('Request successful:', response.status, response.statusText)
			} else {
				//startupModal.show()
				console.error('Request failed:', responseStartupDetail.status, responseStartupDetail.statusText)
			}
		} catch (error) {
			console.error('Fetch error:', error)
		}
	}
}
