import Modal from 'bootstrap/js/src/modal.js'
import getCookie from '../../base/js/getCookie.js'
import generateDynamicCaptcha from '../../base/js/captcha.js'

export const authorizationModal = document.getElementById('authorization-modal')
	? new Modal(document.getElementById('authorization-modal'))
	: null
export const authorizationModalNode = document.getElementById('authorization-modal')
export const thanksModal = document.getElementById('thanks-modal')
	? new Modal(document.getElementById('thanks-modal'))
	: null
export const errorModal = document.getElementById('error-modal')
	? new Modal(document.getElementById('error-modal'))
	: null
export const modalCloseBtns = document.querySelectorAll('.modal-close')

const signUpBtn = document.querySelector('.login__sign-up')
const loginContent = document.querySelector('.authorization__login')
const logInBtn = document.querySelector('.sign-up__login')
const backToLoginBtn = document.querySelector('.password__btn.logIn-hidden')
const signUpContent = document.querySelector('.authorization__sign-up')

const modalAuthorization = document.querySelector('.modal__authorization')

const passwordEmail = document.querySelector('#password_email')

if(passwordEmail){
	var passwordEmailValue = passwordEmail.value

	passwordEmail.addEventListener('input', () => {
		passwordEmailValue = passwordEmail.value
	})
}

const siteLang = getCookie('pll_language') || document.querySelector('.language-dropdown-btn span').textContent
const formatSiteLang = siteLang.toLocaleLowerCase().slice(0, 2)
export const langIsEn = formatSiteLang === 'en'

signUpBtn?.addEventListener('click', () => {
	switchedAuthModalView('showSignUp')
})

logInBtn?.addEventListener('click', () => {
	switchedAuthModalView('showLogin')
})

backToLoginBtn?.addEventListener('click', () => {
	switchedAuthModalView('showLogin')
})

modalCloseBtns.forEach(item => {
	item.addEventListener('click', () => {
		authorizationModal?.hide()
		errorModal?.hide()
		thanksModal?.hide()
	})
})

export const switchedAuthModalView = type => {
	switch (type) {
		case 'showLogin':
			document.querySelector('.authorization__sign-up').style.display = 'none'
			document.querySelector('.authorization__welcome').style.display = 'none'
			document.querySelector('.authorization__password').style.display = 'none'
			document.querySelector('.authorization__login').style.display = 'block'
			authorizationModalNode.querySelector('.modal-title').innerText = langIsEn ? 'Log in' : 'Увійти'
			authorizationModalNode.querySelector('.modal-desc').innerText = langIsEn
				? 'Log in to access your account'
				: 'Увійдіть, щоб отримати доступ до свого облікового запису'
			break
		case 'showSignUp':
			loginContent.style.display = 'none'
			signUpContent.style.display = 'block'
			modalAuthorization.querySelector('.modal-title').innerText = langIsEn ? 'Sign up' : 'Зареєструватися'
			modalAuthorization.querySelector('.modal-desc').innerText = langIsEn
				? 'Give us a little information about yourself'
				: 'Дайте нам трохи інформації про себе'
			break
		case 'showForgotPassword':
			document.querySelector('.authorization__login').style.display = 'none'
			document.querySelector('.authorization__password').style.display = 'block'
			document.querySelector('.modal-title').innerHTML = langIsEn ? 'Forgot password?' : 'Забули пароль?'
			document.querySelector('.password__row').style.display = 'block'
			document.querySelector('.logIn-hidden').style.display = 'none'
			document.querySelector('.password__btn').style.display = 'block'
			document.querySelector('.modal-desc').innerHTML = langIsEn
				? 'Please enter your email address below so we can send you a link to reset your password.'
				: 'Будь ласка, введіть свою адресу електронної пошти нижче, щоб ми могли надіслати вам посилання для скидання пароля.'
			passwordEmailValue = passwordEmail.value
			break
		case 'showVerifyPassword':
			authorizationModalNode.querySelector('.modal-desc').innerHTML = langIsEn
				? `We just sent a password reset link to <span class="emphasize-blue current-email">${passwordEmailValue}</span> Click the link in the email to reset your password. Cant’ find the email? Check spam or <button type="button" class="emphasize-blue resend-pass">click here to resend.</button>`
				: `Ми щойно надіслали посилання для скидання пароля на <span class="emphasize-blue current-email">${passwordEmailValue}</span> Натисніть посилання в електронному листі, щоб скинути пароль. Не можете знайти електронний лист? Перевірте спам або <button type="button" class="emphasize-blue resend-pass">натисніть тут, щоб надіслати повторно.</button>`
			document.querySelector('.password__btn').style.display = 'none'
			document.querySelector('.password__row').style.display = 'none'
			document.querySelector('.logIn-hidden').style.display = 'block'
			const resendPasswordBtn = document.querySelector('.resend-pass')
			resendPasswordBtn?.addEventListener('click', () => {
				switchedAuthModalView('showForgotPassword')
			})
			break
		case 'showPasswordUpdated':
			modalAuthorization.querySelector('.modal-title').innerText = langIsEn
				? 'Password updated'
				: 'Пароль оновлено'
			modalAuthorization.querySelector('.modal-desc').innerText = langIsEn
				? 'We successfully changed your password.'
				: 'Ми успішно змінили ваш пароль.'
			document.querySelector('.authorization__sign-up').style.display = 'none'
			document.querySelector('.authorization__welcome').style.display = 'none'
			document.querySelector('.authorization__password').style.display = 'none'
			document.querySelector('.authorization__login').style.display = 'none'

			break
		default:
			break
	}
}

export const showErrorModal = () => {
	if (!errorModal && !authorizationModal) return
	errorModal.show()
	authorizationModal?.hide()
}

export const showThanksModal = () => {
	if (!thanksModal && !authorizationModal) return
	thanksModal.show()
	authorizationModal?.hide()
}

const openAuthModal = () => {
	if (!authorizationModal) return
	generateDynamicCaptcha()
	authorizationModal.show()
	switchedAuthModalView('showLogin')
}

document.querySelectorAll('.trigger-login-modal').forEach(item => {
	item.addEventListener('click', openAuthModal)
})
