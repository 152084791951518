/* eslint-disable vars-on-top */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import Tooltip from 'bootstrap/js/src/tooltip.js'

var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
tooltipTriggerList.map(tooltipTriggerEl => {
	var tooltip = new Tooltip(tooltipTriggerEl, {
		trigger: 'hover',
		container: 'body',
		boundary: 'window'
	})
})
