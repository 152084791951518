import JustValidate from 'just-validate'
import intlTelInput from 'intl-tel-input'
import Modal from 'bootstrap/js/src/modal.js'
import { langIsEn } from '../authorization/authorization'

import formValidation from '../../base/js/formValidation'

import generateDynamicCaptcha from '../../base/js/captcha'

if (document.querySelector('#inquiry')) {
	const inquiryModal = new Modal(document.getElementById('inquiry-modal'))
	const inquiryModalNode = document.getElementById('inquiry-modal')
	const thanksModal = new Modal(document.getElementById('thanks-modal'))
	const errorModal = new Modal(document.getElementById('error-modal'))

	const inputs = inquiryModalNode.querySelectorAll('.form-control__input ')
	const submitBtn = inquiryModalNode.querySelector('.inquiry__submit')
	const phoneInput = inquiryModalNode.querySelector('#phone')
	const phoneInputPrefix = inquiryModalNode.querySelector('.tel-prefix')

	const modalCloseBtns = document.querySelectorAll('.modal-close')

	document.querySelectorAll('.trigger-inquiry-modal').forEach(item => {
		item.addEventListener('click', () => {
			generateDynamicCaptcha()
			inquiryModal.show()
		})
	})

	const form = inquiryModalNode.querySelector('form')
	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	const countryPicker = intlTelInput(phoneInput, {
		utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
	})

	function setPhoneMask(countryCode) {
		phoneInputPrefix.textContent = `+${countryCode}`
	}

	//const grecaptcha = window.grecaptcha

	phoneInput.addEventListener('countrychange', () => {
		const selectedCountryData = countryPicker.getSelectedCountryData()
		const selectedCountryCode = selectedCountryData.dialCode
		setPhoneMask(selectedCountryCode)
	})

	const initialCountryData = countryPicker.getSelectedCountryData()
	const initialCountryCode = initialCountryData.dialCode
	setPhoneMask(initialCountryCode)

	const validator = new JustValidate(form, {
		lockForm:false
	}).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	}).onFail((event) => {
	    console.log(event);
	});

	if (inquiryModal) {
		const validation = new formValidation(form)

		validation.addControl('#inquiry_name', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#inquiry_company', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#inquiry_occupation', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#inquiry_email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email'
			}
		])
		validation.addControl('#phone', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},

			{
				rule: 'customRegexp',
				value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
				errorMessage: langIsEn
					? 'Please enter a valid phone number'
					: 'Будь ласка, введіть правильний номер телефону'
			},

			{
				rule: 'minLength',
				value: 9,
				errorMessage: langIsEn ? 'The minimum length is 9 characters' : 'Мінімальна довжина 9 знаків'
			},
			{
				rule: 'maxLength',
				value: 12,
				errorMessage: langIsEn ? 'The maximum length is 12 characters' : 'Максимальна довжина 12 знаків'
			}
		])

		
		/*submitBtn.addEventListener('click', () => {

		})*/

		validation.onSuccess(event => {
			if (
				form.querySelector('.g-recaptcha-response') &&
				form.querySelector('.g-recaptcha-response').value == ''
			) {
				console.log('empty_capcha_value');

	          grecaptcha.ready(function () {
	            
	            grecaptcha.execute(
	              form.querySelector(".sw_recaptcha").getAttribute("data-render")
	            ).then(function(token){
	              console.log('executed successfully');
	              return false;
	            });
	          });

	          console.log('capcha execute before request');
	          return false;
			}else{
				var formData = new FormData(form, submitBtn);
				formData.delete('phone');
				formData.append('phone', phoneInputPrefix.textContent + phoneInput.value);
				submitFormData(formData);
				return true;
			}

			
		})

		const submitFormData = formData => {
			return fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					grecaptcha.reset(form.querySelector('.sw_recaptcha').getAttribute('data-render'));
          			console.log('reset capcha');

					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							form.reset()
							inquiryModal.hide()
							thanksModal.show()
						} else {
							document.getElementById('form_error_text').innerHTML = json.error
							errorModal.show()
							inquiryModal.hide()
						}
					} else {
						errorModal.show()
						inquiryModal.hide()
						document.getElementById('form_error_text').innerHTML = ''
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
					}
				})
				.catch(error => {
					errorModal.show()
					inquiryModal.hide()
				})
		}

		document.querySelectorAll('textarea').forEach(textarea => {
			textarea.addEventListener('input', () => {
				textarea.style.height = `${textarea.scrollHeight}px`
			})
		})

		modalCloseBtns.forEach(btn => {
			btn.addEventListener('click', () => {
				inquiryModal.hide()
				errorModal.hide()
				thanksModal.hide()
			})
		})
	}
}
