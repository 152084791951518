/* eslint-disable no-underscore-dangle */
import JustValidate from 'just-validate'
import Modal from 'bootstrap/js/src/modal.js'
import generateDynamicCaptcha from '../../base/js/captcha.js'
import { langIsEn, thanksModal, errorModal } from '../authorization/authorization.js'
import formValidation from '../../base/js/formValidation'

if (document.querySelector('#email-form')) {
	const shareEmailModal = new Modal(document.getElementById('share-email'))
	const shareEmailModalNode = document.getElementById('share-email')

	const submitBtn = shareEmailModalNode.querySelector('.email-form__submit')
	const modalCloseBtns = document.querySelectorAll('.modal-close')

	const form = shareEmailModalNode.querySelector('form')
	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	if (form) {
		const validator = new JustValidate('#email-form').onValidate(({ isSubmitted, fields, groups }) => {
			if (!isSubmitted) return

			Object.keys(fields).forEach(key => {
				const { config, isValid } = fields[key]

				if (isValid) {
					config.errorsContainer.classList.remove('hasError')
				} else {
					config.errorsContainer.classList.add('hasError')
				}
			})
		})

		const addFormControl = (_validator, selector, rules) => {
			const elRef = document.querySelector(selector)

			if (!elRef) {
				throw new Error('No field with selector' + selector + 'found')
			}

			const config = {
				errorsContainer: elRef.closest('.form-control'),
				errorLabelCssClass: ['form-control__error']
			}

			_validator.addField(selector, rules, config)
		}

		document.querySelectorAll('.trigger-share-email-modal').forEach(item => {
			item.addEventListener('click', () => {
				generateDynamicCaptcha()
				shareEmailModal.show()
			})
		})

		const grecaptcha = window.grecaptcha

		const validation = new formValidation(form, {lockForm:false})

		validation.addControl('#email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email',
				errorMessage: langIsEn ? 'Email not valid' : 'Email не валідний'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					grecaptcha.ready(function () {
						grecaptcha.reset()
					})

					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							form.reset()
							shareEmailModal.hide()
							thanksModal.show()
						} else {
							document.getElementById('form_error_text').innerHTML = json.error
							errorModal.show()
							shareEmailModal.hide()
						}
					} else {
						errorModal.show()
						shareEmailModal.hide()
						document.getElementById('form_error_text').innerHTML = ''
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною')
					}
				})
				.catch(error => {
					errorModal.show()
					shareEmailModal.hide()
				})
		}

		modalCloseBtns.forEach(btn => {
			btn.addEventListener('click', () => {
				shareEmailModal.hide()
				errorModal.hide()
				thanksModal.hide()
			})
		})
	}
}
