document.addEventListener('DOMContentLoaded', function () {
	function toggleProfileDropdown() {
		const profileDropdown = document.querySelector('.profile-dropdown.dropdown')
		const windowWidth = window.innerWidth

		if (profileDropdown) {
			if (windowWidth <= 959) {
				profileDropdown.style.display = 'none'
			} else {
				profileDropdown.style.display = 'flex'
			}
		}
	}

	toggleProfileDropdown()

	window.addEventListener('resize', toggleProfileDropdown)
})
