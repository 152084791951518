import Modal from 'bootstrap/js/src/modal.js'

window.onload = () => {
	const pageNewPass = document.querySelector('.page-new-password')
	const newPassModal = pageNewPass ? new Modal('#authorization-modal') : null
	const newPassModalNode = document.querySelector('#authorization-modal')
	if (pageNewPass) {
		newPassModalNode.classList.add('set-new-pass')
		newPassModal.show()
		document.querySelector('.modal-title').innerHTML = 'Set new password'
		document.querySelector('.modal-desc').innerHTML = 'You have requested to reset the password for: jdoe@jdoe.com'

		newPassModalNode.addEventListener('hidden.bs.modal', () => {
			newPassModalNode.classList.remove('set-new-pass')
		})
	}
}
