import JustValidate from 'just-validate'
import intlTelInput from 'intl-tel-input'
import { langIsEn, errorModal, thanksModal, authorizationModal, authorizationModalNode } from '../authorization'

import formValidation from '../../../base/js/formValidation'
const form = authorizationModalNode?.querySelector('#welcome__form')

if (authorizationModalNode && form) {
	const submitBtn = authorizationModalNode.querySelector('.welcome__submit')
	const phoneInput = authorizationModalNode.querySelector('#welcome__phone')
	const phoneInputPrefix = authorizationModalNode.querySelector('.tel-prefix')

	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	const countryPicker = intlTelInput(phoneInput, {
		utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js'
	})

	function setPhoneMask(countryCode) {
		phoneInputPrefix.textContent = `+${countryCode}`
	}

	phoneInput.addEventListener('countrychange', () => {
		const selectedCountryData = countryPicker.getSelectedCountryData()
		const selectedCountryCode = selectedCountryData.dialCode
		setPhoneMask(selectedCountryCode)
	})

	const initialCountryData = countryPicker.getSelectedCountryData()
	const initialCountryCode = initialCountryData.dialCode
	setPhoneMask(initialCountryCode)

	const validator = new JustValidate(form).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (authorizationModal) {
		const validation = new formValidation(form, {
			lockForm:false
		})

		validation.addControl('#welcome__name', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])

		validation.addControl('#welcome__surname', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#welcome__company', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#welcome__occupation', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])
		validation.addControl('#welcome__phone', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'customRegexp',
				value: /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g,
				errorMessage: langIsEn
					? 'Please enter a valid phone number'
					: 'Будь ласка, введіть правильний номер телефону'
			},
			{
				rule: 'minLength',
				value: 9,
				errorMessage: langIsEn ? 'The minimum length is 9 characters' : 'Мінімальна довжина 9 знаків'
			},
			{
				rule: 'maxLength',
				value: 12,
				errorMessage: langIsEn ? 'The maximum length is 12 characters' : 'Максимальна довжина 12 знаків'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			formData.delete('phone')
			formData.append('phone', phoneInputPrefix.textContent + phoneInput.value)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			return fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							form.reset();
							document.getElementById('reg_finish_form_error_text').innerHTML = '';
							
							document.querySelector('.authorization__welcome').style.display = 'none'
							document.querySelector('.authorization__verify-email').style.display = 'block'
						} else {
							document.getElementById('reg_finish_form_error_text').innerHTML = json.error;
						}
					} else {
						document.getElementById('form_error_text').innerHTML = '';
						showErrorModal();
						throw new Error(langIsEn ? 'Network response was not ok' : 'Відповідь мережі була неправильною');
						//const modalAuthorization = document.querySelector('.modal__authorization');
						//document.querySelector('.authorization__welcome').style.display = 'none';
						//document.querySelector('.authorization__verify-email').style.display = 'block';

						//modalAuthorization.querySelector('.modal-title').innerText = 'Verify your email';
						//modalAuthorization.querySelector('.modal-desc').innerHTML =
						//	"We just sent a verification link to <span class='emphasize-blue'>jdoe@jdoe.com.</span> Click the link in the email or enter the code below to verify. Can’t find the email? Check spam or <span class='emphasize-blue'>click here to resend</span>."
					}
				})
				.catch(error => {
					errorModal.show()
					authorizationModal.hide()
				})
		}
	}
}
