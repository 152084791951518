/* eslint-disable quote-props */
/* eslint-disable object-property-newline */
export const MAP_WIDTH = 1200
export const MAP_HEIGHT = 840

export const STATE_STROKE_WIDTH = 2
export const STATE_OPACITY_DEFAULT = 0.9
export const STATE_OPACITY_INACTIVE = 0.6

export const STATE_INFO_FALLBACK = {
	Zakarpattia: {
		title: 'іфвфівіф',
		price: '12500000',
		projects: '11',
		url: 'https://front.swipex.team/investMap/region.html'
	},
	Lviv: { price: '75500000', projects: '12', url: 'https://front.swipex.team/investMap/region.html' },
	Volyn: { price: '1500', projects: '1', url: 'https://front.swipex.team/investMap/region.html' },
	'Ivano-Frankivsk': { price: '11100000', projects: '12', url: 'https://front.swipex.team/investMap/region.html' },
	Ternopil: { price: '12500000', projects: '23', url: 'https://front.swipex.team/investMap/region.html' },
	Rivne: { price: '15500000', projects: '2', url: 'https://front.swipex.team/investMap/region.html' },
	Khmelnytskyi: { price: '15500000', projects: '4', url: 'https://front.swipex.team/investMap/region.html' },
	Zhytomyr: { price: '15500000', projects: '2', url: 'https://front.swipex.team/investMap/region.html' },
	Vinnytsia: { price: '1500000', projects: '53', url: 'https://front.swipex.team/investMap/region.html' },
	Kyiv: { price: '55500000', projects: '3', url: 'https://front.swipex.team/investMap/region.html' },
	'Kyiv-City': { price: '75500000', projects: '4', url: 'https://front.swipex.team/investMap/region.html' },
	Odesa: { price: '12500000', projects: '6', url: 'https://front.swipex.team/investMap/region.html' },
	Cherkasy: { price: '12500000', projects: '12', url: 'https://front.swipex.team/investMap/region.html' },
	Chernihiv: { price: '2550000', projects: '44', url: 'https://front.swipex.team/investMap/region.html' },
	Chernivtsi: { price: '15500000', projects: '5', url: 'https://front.swipex.team/investMap/region.html' },
	Kirovohrad: { price: '11100000', projects: '6', url: 'https://front.swipex.team/investMap/region.html' },
	Mykolaiv: { price: '15500000', projects: '7', url: 'https://front.swipex.team/investMap/region.html' },
	Kherson: { price: '2550000', projects: '8', url: 'https://front.swipex.team/investMap/region.html' },
	Poltava: { price: '11100000', projects: '9', url: 'https://front.swipex.team/investMap/region.html' },
	Sumy: { price: '15500000', projects: '6', url: 'https://front.swipex.team/investMap/region.html' },
	Dnipropetrovsk: { price: '15500000', projects: '5', url: 'https://front.swipex.team/investMap/region.html' },
	Sevastopol: { price: '11100000', projects: '2', url: 'https://front.swipex.team/investMap/region.html' },
	Crimea: { price: '2550000', projects: '9', url: 'https://front.swipex.team/investMap/region.html' },
	Kharkiv: { price: '15500000', projects: '3', url: 'https://front.swipex.team/investMap/region.html' },
	Zaporizhzhia: { price: '15500000', projects: '6', url: 'https://front.swipex.team/investMap/region.html' },
	Donetsk: { price: '15500000', projects: '5', url: 'https://front.swipex.team/investMap/region.html' },
	Luhansk: { price: '2550000', projects: '6', url: 'https://front.swipex.team/investMap/region.html' }
}

export const STATE_COLORS = {
	Zakarpattia: '#0057b7',
	Lviv: '#8dc84c',
	Volyn: '#61bceb',
	'Ivano-Frankivsk': '#eb4ab2',
	Ternopil: '#ff9d4d',
	Rivne: '#ed5052',
	Khmelnytskyi: '#7e7eec',
	Zhytomyr: '#0057b7',
	Vinnytsia: '#8dc84c',
	Kyiv: '#ff9d4d',
	'Kyiv-City': '#4991ff',
	Odesa: '#61bceb',
	Cherkasy: '#7e7eec',
	Chernihiv: '#50b29b',
	Chernivtsi: '#50b29b',
	Kirovohrad: '#0057b7',
	Mykolaiv: '#ef49b2',
	Kherson: '#ff9d4d',
	Poltava: '#ed5052',
	Sumy: '#0057b7',
	Dnipropetrovsk: '#61bceb',
	Sevastopol: '#8dc84c',
	Crimea: '#8dc84c',
	Kharkiv: '#8dc84c',
	Zaporizhzhia: '#50b29b',
	Donetsk: '#0057b7',
	Luhansk: '#ff9d4d'
}

export const BREAKPOINTS_CONFIG = {
	xs: 0,
	sm: 576,
	md: 728,
	lg: 960,
	xl: 1200
}
