// const telWrap = document.querySelectorAll('.tel-wrap')

// telWrap.forEach(item => {
// 	let telInput = item.querySelector('.form-control__input')
// 	let telPrefix = item.querySelector('.tel-prefix')
// 	let countryOptions = item.querySelector('.iti__country')
// 	telInput.value = telPrefix.textContent + telInput.value

// 	telPrefix.addEventListener('change', () => {
// 		telInput.value = telPrefix.textContent
// 		console.log(telInput.value)
// 	})

// 	// countryOptions.addEventListener('click', () => {
// 	// 	console.log(telPrefix.textContent)
// 	// 	telInput.value = telPrefix.textContent
// 	// })
// })
