/* eslint-disable new-cap */
import StickySidebar from 'sticky-sidebar'

import Toastify from 'toastify-js'

const sidebar = document.querySelectorAll('.sidebar')
const shareLinks = document.querySelectorAll('.project-page__stat-share-link')
const url = encodeURIComponent(window.location.href)

if (sidebar.length) {
	const customSidebar = new StickySidebar('.sidebar', { topSpacing: 100 })
}

document.addEventListener('DOMContentLoaded', () => {
	const copyButtons = document.querySelectorAll('.copy')

	copyButtons.forEach(copyButton => {
		copyButton.addEventListener('click', () => {
			const pageURL = window.location.href

			// Create a temporary input element to copy the URL to the clipboard
			const tempInput = document.createElement('input')
			tempInput.style.position = 'absolute'
			tempInput.style.left = '-1000px'
			tempInput.value = pageURL
			document.body.appendChild(tempInput)

			// Select and copy the URL from the temporary input element
			tempInput.select()
			document.execCommand('copy')

			// Remove the temporary input element
			document.body.removeChild(tempInput)

			// Show a toast message to indicate the URL has been copied
			Toastify({
				text: 'URL copied to clipboard!',
				close: true,
				duration: 10000, // 3 seconds
				gravity: 'top', // Show toast at the bottom
				position: 'center' // Show toast on the right side
			}).showToast()
		})
	})
})

shareLinks.forEach(shareLink => {
	if (shareLink.classList.contains('facebook')) {
		shareLink.addEventListener('click', () => {
			window.open(
				`https://www.facebook.com/sharer/sharer.php?u=${url}`,
				'facebook-share-dialog',
				'width=626,height=436'
			)

			return false
		})
	}
	if (shareLink.classList.contains('linkedin')) {
		shareLink.addEventListener('click', () => {
			window.open(
				`https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
				'linkedin-share-dialog',
				'width=626,height=436'
			)

			return false
		})
	}
})
