/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

const generateDynamicCaptcha = () => {
	if (
		document.querySelector(
			'script[src="https://www.google.com/recaptcha/api.js?onload=sw_recapcha_callback&render=explicit"]'
		) === null
	) {
		const script = document.createElement('script')
		const head = document.head || document.getElementsByTagName('head')[0]
		script.type = 'text/javascript'
		script.src = 'https://www.google.com/recaptcha/api.js?onload=sw_recapcha_callback&render=explicit'
		script.async = true
		script.defer = true
		head.appendChild(script)
	}
}

window.sw_recapcha_callback = function (selector) {
	if (!selector) {
		selector = document.querySelectorAll('.sw_recaptcha')
	}

	selector.forEach(element => {
		var sw_recapcha = element
		if (sw_recapcha.querySelector('.g-recaptcha-response') === null) {
			var item_id = sw_recapcha.id
			grecaptcha.ready(function () {
				var res_render = grecaptcha.render(item_id, {
					sitekey: sw_recapcha.getAttribute('data-sitekey'),
					size: 'invisible',
					callback: function (response) {
						//sw_recapcha.parentNode.querySelector('input[type=hidden]').value = token
						//sw_recapcha.querySelector('input[name="recaptcha"]').value = token
						//console.log(response);
						//document.getElementById(item_id).closest('form').querySelector('.g-recaptcha-response').value = response;
						
						document.getElementById(item_id).closest('form').querySelector('button[type="submit"]').click()
					}
				})
				sw_recapcha.setAttribute('data-render', res_render)
			})
		}
	})
}

export default generateDynamicCaptcha
