/* eslint-disable no-shadow */
/* eslint-disable no-use-before-define */
/* eslint-disable no-inner-declarations */
/* eslint-disable new-cap */
import * as d3 from 'd3'
import * as Breakjs from 'breakjs'

import {
	MAP_WIDTH,
	MAP_HEIGHT,
	STATE_STROKE_WIDTH,
	STATE_OPACITY_DEFAULT,
	STATE_OPACITY_INACTIVE,
	STATE_INFO_FALLBACK,
	STATE_COLORS,
	BREAKPOINTS_CONFIG
} from './investment-map.consts'
import {
	prepareKey,
	selectSvg,
	createStateGroups,
	createStatePaths,
	createStateTexts,
	createTooltip,
	toBeatifulPrice,
	toBeatifulProjects
} from './investment-map.utils'

const IS_DEV_ENV = location.hostname === "localhost" || location.hostname === "127.0.0.1";

const mapRef = document.getElementById('investment-map')

if (mapRef) {
	let STATE_INFO = mapRef.dataset.schema ? JSON.parse(mapRef.dataset.schema) : STATE_INFO_FALLBACK

	// 1. initialize breakpoints on what will be based map behavior
	const layout = Breakjs(BREAKPOINTS_CONFIG)

	// 2. initialize data
  const PATH_TO_JSON = IS_DEV_ENV ? '/assets/geojson.json' : '/wp-content/themes/investmap/ajax/geojson.json';
	const data = await d3.json(PATH_TO_JSON)
	const projection = d3.geoMercator().fitSize([MAP_WIDTH, MAP_HEIGHT], data)
	let path = d3.geoPath().projection(projection)

	// 3. create and select all usefull elements
	const svg = selectSvg()
	const container = d3.select('#investment-map-container')
	const commonGroup = svg.append('g')
	const { groups, filteredGroups } = createStateGroups(data.features, commonGroup)
	const statePaths = createStatePaths(data.features, commonGroup, path)
	const texts = createStateTexts(data.features, commonGroup, path, STATE_INFO)
	const { tooltipCoatOfArms, tooltipPrice, tooltipDescription, tooltipRef } = createTooltip(container)
	const zoomPlusTrigger = document.getElementById('js-zoom-plus-trigger')
	const zoomMinusTrigger = document.getElementById('js-zoom-minus-trigger')
	const popupBackTrigger = document.getElementById('js-popup-back-trigger')
	const twoFingersTarget = document.getElementById('js-two-fingers-target')
	// 4. create zoom variable to have global access
	let zoom
	let enubleOneFinger = false

	// 5. initialize map and reapre map behaviour
	handleMapBehaviour(layout.current())
	layout.addChangeListener(handleMapBehaviour)

	let timeoutRef
	function showMsg() {
		twoFingersTarget.classList.add('isActive')
	}
	function hideMsg() {
		twoFingersTarget.classList.remove('isActive')
		timeoutRef = null
	}

	svg.node().addEventListener('touchstart', e => {
		if (e.touches.length === 1) {
			if (timeoutRef) {
				return
			}

			showMsg()
			timeoutRef = setTimeout(hideMsg, 1000)
		}

		if (e.touches.length > 1) {
			hideMsg()
			if (timeoutRef) {
				clearTimeout(timeoutRef)
				timeoutRef = null
			}
		}
	})

	function twofingerScenarion(event) {
		const res = event.touches && (enubleOneFinger || event.touches.length === 2)

		if (res) {
			hideMsg()
		}

		return res
	}

	function scaleUp() {
		zoomScaleByStep(1.4)
	}

	function scaleDown() {
		zoomScaleByStep(0.6)
	}

	function zoomScaleByStep(step) {
		zoom.scaleBy(svg.transition().duration(750), step)
	}

	function handleMapBehaviour(layout) {
		// if desktop view:
		if (['xl', 'md', 'lg'].includes(layout)) {
			// 1. reset and configure zoom and pan behaviour
			zoom = d3
				.zoom()
				.filter(twofingerScenarion)
				.extent([
					[0, 0],
					[MAP_WIDTH, MAP_HEIGHT]
				])
				.scaleExtent([1, 2])
				.translateExtent([
					[0, 0],
					[MAP_WIDTH / 2, MAP_HEIGHT / 2]
				])

			// 2. reset manipulation that can be maded on the mobile view
			stopZoomPan()
			unlockScroll()
			mouseleaved()

			// 3. reset the zoom position to the default
			svg.transition()
				.duration(750)
				.call(
					zoom.transform,
					d3.zoomIdentity.translate(0, 0).scale(1),
					d3.zoomTransform(svg.node()).invert([MAP_WIDTH, MAP_HEIGHT])
				)

			// 4. reset and attach map items listeners
			zoomPlusTrigger.removeEventListener('click', scaleUp)
			zoomMinusTrigger.removeEventListener('click', scaleDown)
			popupBackTrigger.removeEventListener('click', closePopup)
			commonGroup.on('mouseleave', mouseleaved)
			filteredGroups.on('click', clicked)
			filteredGroups.on('mouseenter', hovered)
			zoom.on('zoom', zoomed)
			startZoomPan()

			return
		}

		// if mobile view:
		// 1. reset and configure zoom and pan behaviour
		zoom = d3
			.zoom()
			.filter(twofingerScenarion)
			.extent([
				[0, 0],
				[MAP_WIDTH, MAP_HEIGHT]
			])
			.scaleExtent([1, 2.2])
			.translateExtent([
				[0, 0],
				[MAP_WIDTH, MAP_HEIGHT]
			])

		// 2. reset the zoom position to the default
		svg.transition()
			.duration(750)
			.call(
				zoom.transform,
				d3.zoomIdentity.translate(0, 0).scale(1),
				d3.zoomTransform(svg.node()).invert([MAP_WIDTH / 2, MAP_HEIGHT / 2])
			)

		// 4. reset and attach map items listeners
		commonGroup.on('mouseleave', null)
		filteredGroups.on('click', null)
		filteredGroups.on('mouseenter', null)
		filteredGroups.on('click', mobileTap)
		zoom.on('zoom', zoomed)
		startZoomPan()
		zoomPlusTrigger.addEventListener('click', scaleUp)
		zoomMinusTrigger.addEventListener('click', scaleDown)
		popupBackTrigger.addEventListener('click', closePopup)
	}

	function mobileTap(event, d) {
		clicked(event, d)
		hovered.bind(this)(event, d)
	}

	function zoomed(event) {
		const { transform } = event
		commonGroup.attr('transform', transform)
		commonGroup.attr('stroke-width', STATE_STROKE_WIDTH / transform.k)
	}

	function startZoomPan() {
		svg.call(zoom) // attach the zoom listeners
	}

	function stopZoomPan() {
		svg.on('.zoom', null) // remove the zoom listeners
	}

	function hovered(event, d) {
		// 1. reset styles for the all refions

		groups
			.selectAll('path')
			.style('fill', d => STATE_COLORS[prepareKey(d.properties.NAME_1)])
			.style('fill-opacity', STATE_OPACITY_INACTIVE)
			.attr('filter', '')
		groups.selectAll('rect').style('fill', 'none').style('stroke', 'none')
		groups.selectAll('text').style('fill', 'none')

		const that = d3.select(this)

		// 2. make the hovered region higher in the view, via reordering to the last child of its parent
		that.raise()

		// 3. add hightlight styles to the hovered state
		// state
		that.select('path')
			.style('fill', '#FAD419')
			.style('fill-opacity', STATE_OPACITY_DEFAULT)
			.attr('filter', 'url(#whiteOutlineEffect)')
		// label
		that.select('rect').style('fill', '#ffffff').style('stroke', '#ffffff')
		// text
		const text = that.select('text')
		text.style('fill', '#002266').attr('filter', 'none')

		// 4. prepare and show desktop tooltip
		const textBbox = text.node().getBBox()

		// we need this multiplier, because the tooltip position is not in correct coordinates
		const multiplierMap = {
			xl: 0.05,
			lg: 0.25,
			md: 0.45
		}
		const multiplier = multiplierMap[layout.current()]
		const OFFSSET_Y = textBbox.y * multiplier + 100
		const OFFSSET_X = textBbox.x * multiplier + 40

		tooltipRef.style.display = 'block'
		tooltipRef.style.top = `${textBbox.y - OFFSSET_Y}px`
		tooltipRef.style.left = `${textBbox.x - OFFSSET_X}px`

		const KEY = prepareKey(d.properties.NAME_1)
		tooltipCoatOfArms.attr('class', `map-tooltip__coat coats-of-arms-sprite ${KEY}`)
		tooltipPrice.text(toBeatifulPrice(STATE_INFO[KEY].price))
		tooltipDescription.text(toBeatifulProjects(STATE_INFO[KEY].projects))
	}

	// Reset all manipulations with states to the initial state (hover, click, focus, etc):
	function mouseleaved(event) {
		if (event) {
			event.stopPropagation()
		}

		tooltipRef.style.display = 'none'

		groups.selectAll('text').style('fill', '#ffffff').attr('filter', 'url(#textOutlineEffect)')
		groups
			.selectAll('path')
			.style('fill', d => STATE_COLORS[prepareKey(d.properties.NAME_1)])
			.style('fill-opacity', STATE_OPACITY_DEFAULT)
			.attr('filter', '')
		groups.selectAll('rect').style('fill', 'none').style('stroke', 'none')

		groups.order() // order all groups to the default position
	}

	function clicked(event, d) {
		// if mobile view:
		if (['xs', 'sm'].includes(layout.current())) {
			// 1. show the mobile popup
			openPopup(event, d)

			// 2. center the selected region
			// no need for right now
			// const [[x0, y0], [x1, y1]] = path.bounds(d);
			// svg.transition().duration(750).call(
			//   zoom.transform,
			//   d3.zoomIdentity
			//     .translate(MAP_WIDTH / 2, MAP_HEIGHT / 2)
			//     .scale(Math.min(8, 0.6 / Math.max((x1 - x0) / MAP_WIDTH, (y1 - y0) / MAP_HEIGHT)))
			//     .translate(-(x0 + x1) / 2, -(y0 + y1) / 2),
			//   d3.pointer(event, svg.node())
			// );

			return
		}
		// if desktop view:
		// 1. link to the state details page
		const KEY = prepareKey(d.properties.NAME_1)
		window.location.href = STATE_INFO[KEY].url
	}

	let prevKey = ''
	function openPopup(event, d) {
		// 1. Select elements
		const mapContainerRef = document.getElementById('investment-map-container')
		const titleRef = document.getElementById('js-popup-title')
		const coatRef = document.getElementById('js-popup-coat')
		const priceRef = document.getElementById('js-popup-price')
		const projectsRef = document.getElementById('js-popup-projects')
		const btnRef = document.getElementById('js-popup-btn')

		// 2. Set content to element
		const KEY = prepareKey(d.properties.NAME_1)
		titleRef.innerText = KEY
		priceRef.innerText = toBeatifulPrice(STATE_INFO[KEY].price)
		projectsRef.innerText = toBeatifulProjects(STATE_INFO[KEY].projects)
		coatRef.classList.add(KEY)
		if (prevKey) {
			coatRef.classList.remove(prevKey)
		}
		prevKey = KEY
		btnRef.href = STATE_INFO[KEY].url

		// 3. Scroll to the start position
		mapContainerRef.scrollIntoView({
			behavior: 'smooth'
		})

		// 4. Lock the scroll
		document.body.classList.add('map-popup-is-opened')

		// 5. enuble one finger behaviour
		enubleOneFinger = true
	}

	function closePopup() {
		// 1. Scroll position in to middle alignment of the map container
		const mapContainerRef = document.getElementById('investment-map-container')
		const rect = mapContainerRef.getBoundingClientRect()
		const MOB_FIXED_MAP_HEIGHT = 480
		const absoluteElementTop = rect.top + window.pageYOffset + MOB_FIXED_MAP_HEIGHT / 2
		const middle = absoluteElementTop - window.innerHeight / 2
		window.scrollTo(0, middle)

		// 2. Reset manipulations with states (hover, click, focus, etc)
		mouseleaved()

		// 3. Set map in the mobile default position
		svg.transition()
			.duration(750)
			.call(
				zoom.transform,
				d3.zoomIdentity.translate(-MAP_WIDTH / 4, -MAP_HEIGHT / 4).scale(1.5),
				d3.zoomTransform(svg.node()).invert([MAP_WIDTH / 2, MAP_HEIGHT / 2])
			)

		// 4. Unlock scrolling
		unlockScroll()

		// 5. disable one finger behaviour
		enubleOneFinger = false
	}

	function unlockScroll() {
		document.body.classList.remove('map-popup-is-opened')
	}
}
