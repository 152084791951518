/* eslint-disable no-underscore-dangle */
import Modal from "bootstrap/js/src/modal.js";
import JustValidate from "just-validate";
import generateDynamicCaptcha from "../../base/js/captcha.js";
import formValidation from "../../base/js/formValidation";
import {
  errorModal,
  langIsEn,
  thanksModal,
} from "../authorization/authorization.js";

if (document.querySelector("#submitProject")) {
  const submitProjectModal = new Modal(
    document.getElementById("project-modal")
  );
  const submitProjectModalNode = document.getElementById("project-modal");

  const submitBtn = submitProjectModalNode.querySelector(
    ".submit-project__btn button"
  );

  const modalCloseBtns = document.querySelectorAll(".modal-close");

  document.querySelectorAll(".submit-project-trigger-modal").forEach((item) => {
    item.addEventListener("click", () => {
      generateDynamicCaptcha();
      submitProjectModal.show();
    });
    return false;
  });

  const form = submitProjectModalNode.querySelector("form");
  const formAction = form.getAttribute("action");
  const formMethod = form.getAttribute("method");

  const validator = new JustValidate("#submitProject", {
    lockForm: false,
  }).onValidate(({ isSubmitted, fields, groups }) => {
    console.log(isSubmitted);
    console.log(fields);
    console.log(groups);
    if (!isSubmitted) return;

    Object.keys(fields).forEach((key) => {
      const { config, isValid } = fields[key];

      if (isValid) {
        config.errorsContainer.classList.remove("hasError");
      } else {
        config.errorsContainer.classList.add("hasError");
      }
    });
  }).onFail((event) => {
    console.log(event);
  });

  const addFormControl = (_validator, selector, rules) => {
    const elRef = document.querySelector(selector);

    if (!elRef) {
      throw new Error("No field with selector" + selector + "found");
    }

    const config = {
      errorsContainer: elRef.closest(".form-control"),
      errorLabelCssClass: ["form-control__error"],
    };

    _validator.addField(selector, rules, config);
  };
  if (submitProjectModal) {
    const validation = new formValidation("#submitProject");
    validation.addControl("#project-name", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    validation.addControl("#project-address", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    validation.addControl("#project-initiator", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    // validation.addControl('#project-sum', [
    // 	{
    // 		rule: 'required',
    // 		errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
    // 	}
    // ])
    validation.addControl("#project-opportunity", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    validation.addControl("#project-status", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    /*validation.addControl('#project-invest', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			}
		])*/
    validation.addControl("#project-sector", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "This field is required"
          : "Будь ласка, заповніть поле",
      },
    ]);
    validation.addControl("#project-description", [
      {
        validator: (value) => {
          return value !== undefined && value.length > 3;
        },
        errorMessage: langIsEn
          ? "Message should be more than 3 letters."
          : "Повідомлення повинно бути більше 3 букв.",
      },
    ]);

    validation.addControl("#privacy", [
      {
        rule: "required",
        errorMessage: langIsEn
          ? "Please accept our privacy policy"
          : "Будь ласка, прийміть нашу політику конфіденційності",
      },
    ]);

    validation.onSuccess((event) => {
      if (form.querySelector(".g-recaptcha-response")) {
        if (form.querySelector(".g-recaptcha-response").value == "") {
          console.log('empty_capcha_value');

          grecaptcha.ready(function () {
            
            grecaptcha.execute(
              form.querySelector(".sw_recaptcha").getAttribute("data-render")
            ).then(function(token){
              console.log('executed successfully');
              return false;
            });
          });

          console.log('capcha execute before request');
          return false;
        }else{
          console.log('capcha value is ok');
          const formData = new FormData(form, submitBtn);
          submitFormData(formData);
          return false;
        }
      }else{
        console.log('form has not capcha field');
        return false;
      }

      
      //const formEl = event.srcElement
      
    });

    modalCloseBtns.forEach((btn) => {
      btn.addEventListener("click", () => {
        submitProjectModal.hide();
        thanksModal.hide();
        errorModal.hide();
      });
    });

    const submitFormData = (formData) => {
      fetch(formAction, {
        method: formMethod,
        body: formData,
      })
        .then(async (response) => {
          
          grecaptcha.reset(form.querySelector('.sw_recaptcha').getAttribute('data-render'));
          console.log('reset capcha');

          if (response.ok) {
            const json = await response.json();
            if (json.success === 1) {
              form.reset();

              const files_vals = form.querySelectorAll(".form-control__file-remove");
              if(files_vals.length > 0){
                files_vals.forEach((fileval) => {
                  fileval.click();
                });

              }

              submitProjectModal.hide();
              thanksModal.show();
            } else {
              console.log(json);

              document.getElementById("form_error_text").innerHTML = json.error;
              errorModal.show();
              submitProjectModal.hide();
            }


          }else{
            console.log('bad response');

            errorModal.show();
            submitProjectModal.hide();
            document.getElementById("form_error_text").innerHTML = "";
            throw new Error(
              langIsEn
                ? "Network response was not ok"
                : "Відповідь мережі була неправильною"
            );
          }
        })
        .catch((error) => {
          console.log(error);

          errorModal.show();
          submitProjectModal.hide();
        });
    };

    document.querySelectorAll("textarea").forEach((textarea) => {
      textarea.addEventListener("input", () => {
        textarea.style.height = `${textarea.scrollHeight}px`;
      });
    });
  }
}
