const modals = document.querySelectorAll('.modal')

//add animation to madals

modals.forEach(modal => {
	const inputs = modal.querySelectorAll('.form-control__label')
	inputs.forEach(input => {
		input.addEventListener('click', () => {
			if (!modal.classList.contains('active')) {
				modal.classList.add('active')
			}
		})
	})
	modal.addEventListener('hidden.bs.modal', () => {
		modal.classList.remove('active')
	})
})
