/* eslint-disable new-cap */
/* eslint-disable no-new */
import simpleParallax from 'simple-parallax-js'

// parallax
var images = document.querySelectorAll('.parallax')
new simpleParallax(images)

// collapse

document.addEventListener('DOMContentLoaded', () => {
	if (document.querySelector('.region-page') !== null) {
		const readMoreBtn = document.querySelector('.region-page__desc-btn')
		const readMoreBtnTxt = readMoreBtn?.querySelector('span')
		const extraContent = document.querySelector('.region-page__desc-inner')
		let innerHeight = document.querySelector('.region-page__desc-inner-txt')?.scrollHeight

		if (readMoreBtn !== null) {
			readMoreBtn.addEventListener('click', () => {
				if (!extraContent.classList.contains('active')) {
					extraContent.style.maxHeight = `${extraContent?.scrollHeight}px`
					extraContent.classList.add('active')
					readMoreBtn.classList.add('active')
					readMoreBtnTxt.textContent = 'Collapse'
				} else {
					extraContent.style.maxHeight = '180px'
					extraContent.classList.remove('active')
					readMoreBtn.classList.remove('active')
					readMoreBtnTxt.textContent = 'Expand'
				}
			})
		}

		const checkHeight = () => {
			innerHeight = document.querySelector('.region-page__desc-inner-txt')?.scrollHeight

			if (innerHeight <= 180) {
				readMoreBtn.style.display = 'none'
				extraContent.classList.add('active')
				readMoreBtn.classList.remove('active')
			} else {
				readMoreBtn.style.display = ''
				extraContent.style.maxHeight = '180px'
				extraContent.classList.remove('active')
				readMoreBtn.classList.remove('active')
				readMoreBtnTxt.textContent = 'Expand'
			}
		}

		window.addEventListener('resize', () => {
			checkHeight()
		})
		checkHeight()
	}
})
