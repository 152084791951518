import JustValidate from 'just-validate'
import formValidation from '../../../base/js/formValidation'
import { langIsEn, showThanksModal, showErrorModal, authorizationModal, authorizationModalNode } from '../authorization'
const form = authorizationModalNode?.querySelector('#sign-up__form')

if (authorizationModalNode && form) {
	const submitBtn = authorizationModalNode.querySelector('.sign-up__submit')

	const formAction = form.getAttribute('action')
	const formMethod = form.getAttribute('method')

	const validator = new JustValidate(form).onValidate(({ isSubmitted, fields, groups }) => {
		if (!isSubmitted) return

		Object.keys(fields).forEach(key => {
			const { config, isValid } = fields[key]

			if (isValid) {
				config.errorsContainer.classList.remove('hasError')
			} else {
				config.errorsContainer.classList.add('hasError')
			}
		})
	})

	const addFormControl = (_validator, selector, rules) => {
		const elRef = document.querySelector(selector)

		if (!elRef) {
			throw new Error('No field with selector' + selector + 'found')
		}

		const config = {
			errorsContainer: elRef.closest('.form-control'),
			errorLabelCssClass: ['form-control__error']
		}

		_validator.addField(selector, rules, config)
	}

	if (authorizationModal) {
		const validation = new formValidation(form, {
			lockForm: false
		})

		validation.addControl('#sign-up_email', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'email'
			}
		])

		validation.addControl('#sign-up_password', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password'
			}
		])

		validation.addControl('#sign-up_confirm_password', [
			{
				rule: 'required',
				errorMessage: langIsEn ? 'This field is required' : 'Будь ласка, заповніть поле'
			},
			{
				rule: 'password'
			}
		])

		validation.onSuccess(event => {
			const formEl = event.srcElement
			const formData = new FormData(formEl, submitBtn)
			submitFormData(formData)
		})

		const submitFormData = formData => {
			fetch(formAction, {
				method: formMethod,
				body: formData
			})
				.then(async response => {
					grecaptcha.ready(function () {
						grecaptcha.reset()
					})

					if (response.ok) {
						const json = await response.json()
						if (json.success === 1) {
							// show next form
							form.reset()
							document.getElementById('reg_form_error_text').innerHTML = ''

							document.querySelector('.authorization__sign-up').style.display = 'none'
							document.querySelector('.authorization__welcome').style.display = 'block'
						} else {
							document.getElementById('reg_form_error_text').innerHTML = json.error
							//showErrorModal()
						}
					} else {
						showErrorModal()
					}
				})
				.catch(error => {
					showErrorModal()
				})
		}
	}
}
